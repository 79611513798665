import { Link, useLocation } from "react-router-dom";

const folders = [
  {
    name: '🤑 Normal',
    options: [
      { id: '01', name: 'No ready', description: '', value: 'no_ready', },
      { id: '01', name: 'Ready to fund', description: '', value: 'ready_fund', },
      { id: '01', name: 'Me Salve', description: '', value: 'mesalve', },
      { id: '01', name: 'Transfers', description: '', value: 'transfers', },
    ]
  },
  {
    name: '👷‍♂️ Credit Builder',
    options: [
      { id: '01', name: 'Actived', description: '', value: 'cb_actived', },
    ]
  },
  // {
  //     name: '🛍️ Kiwi Pay',
  //     options: [
  //         { id: '01', name: 'Ready to fund', description: '', value: 'new', },
  //         { id: '01', name: 'Funded', description: '', value: 'signed', }
  //     ]
  // },
]

function LoansFilters() {

  const { pathname } = useLocation();

  return (
    <div className="loans_filters w-72 h-dashboard overflow-auto sticky top-0 border-r">
      <ul className="pt-6">
        {folders.map((folder, index) => (
          <li key={index} className="px-5">
            <p>{folder.name}</p>
            <ul className="ml-6 mt-1 mb-2 border-l border-black">
              {folder.options.map((option, key) => (
                <li className="flex" key={key}>
                  <Link
                    to={`/loans/${option.value}`}
                    className={`px-4 py-2 ${pathname.includes(option.value)
                      ? "text-blue-kiwi font-semibold"
                      : "text-gray-600"
                      }`}
                  >
                    <p>
                      {option.name}{" "}
                      <span className="text-gray-400">{option.description}</span>
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LoansFilters;