import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import Header from "../components/Header";

const Dashboard = () => {

    let navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        isAuthenticate();
    }, [])
    
    const isAuthenticate = () => {
        const cookie = Cookies.get('kiwi_auth_token');
        if(!cookie) {
            setLoading(false);
            navigate("/auth");
        }
        setLoading(false);
    }

    return loading ?
        <div>
            <h1>Loading</h1>
        </div>
        :
        <div>
            <Header />
            <Outlet />
        </div>
}

export default Dashboard;