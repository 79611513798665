import axios from 'axios';
import Cookies from 'js-cookie';

const HEADERS = () => {
  return { authorization: `Bearer ${Cookies.get('kiwi_auth_token')}` };
};

export const LoanServices = {
  getNotReadyLoans: async ({
    page = 1,
    page_size = 50,
    start_date,
    end_date,
  }) => {
    return await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/loans/no-ready`,
      params: {
        page,
        page_size,
        start_date,
        end_date,
      },
      headers: HEADERS(),
    });
  },

  getReadyToFoundLoans: async ({
    page = 1,
    page_size = 50,
    start_date,
    end_date,
  }) => {
    return await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/loans/ready-to-found`,
      params: {
        page,
        page_size,
        start_date,
        end_date,
      },
      headers: HEADERS(),
    });
  },

  getMeSalveLoans: async ({
    page = 1,
    page_size = 50,
    start_date,
    end_date,
  }) => {
    return await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/loans/mesalve`,
      params: {
        page,
        page_size,
        start_date,
        end_date,
      },
      headers: HEADERS(),
    });
  },

  getTransfersLoans: async ({
    page = 1,
    page_size = 50,
    start_date,
    end_date,
  }) => {
    return await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/loans/transfers`,
      params: {
        page,
        page_size,
        start_date,
        end_date,
      },
      headers: HEADERS(),
    });
  },

  getCBLoans: async ({ page = 1, page_size = 50, start_date, end_date }) => {
    return await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/loans/credit-builder`,
      params: {
        page,
        page_size,
        start_date,
        end_date,
      },
      headers: HEADERS(),
    });
  },

  getAllDocuments: async id => {
    return await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/loan/${id}/documents`,
      headers: HEADERS(),
    });
  },

  getDocumentByData: async (document_id, file_name, config = {}) => {
    return await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/loan/document/download`,
      headers: HEADERS(),
      data: {
        document_id,
        file_name,
      },
      responseType: 'blob',
      ...config,
    });
  },
};
