import axios from "axios";
import Loader from "components/UI/Loader";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "redux/reducer";

const AuthOkta = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getQueryParams = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const error = urlParams.get("error");
      const error_description = urlParams.get("error_description");

      if (code) {
        setIsLoading(!isLoading);
        try {
          const response = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/oauth/okta`,
            data: {
              code: code,
            },
          });

          if (response.data?.data?.access_token) {
            const { access_token } = response.data.data;

            Cookies.set("kiwi_auth_token", access_token);
            let domain = getDomain();
            Cookies.set("kiwi_gpt_dashboard_token", access_token, {
              domain,
            });

            setIsLoading(!isLoading);

            navigate("/");
            dispatch(actions.getAuthUser());
          }
        } catch (error) {
          setIsLoading(!isLoading);
          alert("Login with Okta Failed");
          navigate("/auth");
        }
      } else if (error && error_description) {
        setIsLoading(!isLoading);
        alert(error_description);
        navigate("/auth");
      }
    };

    getQueryParams();
  }, [navigate]);

  const getDomain = () => {
    let domain = process.env.REACT_APP_GPT_DASHBOARD || "";

    if (domain.startsWith("localhost")) {
      return "localhost";
    }

    return "." + domain.split(".").slice(1).join(".");
  };

  return (
    <div className="h-screen flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              className="h-10 w-auto"
              src={require("../assets/kiwi-logo.png")}
              alt="Workflow"
            />
            <p>Sign in with okta, please wait...</p>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
          alt=""
        />
      </div>

      <Loader status={isLoading} />
    </div>
  );
};

export default AuthOkta;
