import React, { useState } from 'react'
import Promote from './Promote'
import CreditLimitIncrease from 'services/CreditLimitIncrease/CreditLimitIncrease.services'

const ApplyIncreaseLimit = ({ application, onMove, onCreate }) => {
    const [selectOption, setSelectOption] = useState("")

    const handleChange = (event) => {

        setSelectOption(event.target.value)
    }

    const handleRejectCreditLimitIncrease = async () => {
        try {
            await CreditLimitIncrease.putRejectCreditLimitIncrease({
                application_id: application.id,
                reason: "no_applicable"
            })
        } catch (error) {
            alert("ERROR")
        } finally {
            setSelectOption("")
            onMove("list");
        }
    }

    return (
        <div className="mt-auto">
            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                Action
            </label>
            <select
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
                onChange={handleChange}
            >
                <option>Choose an option</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
            </select>
            {selectOption === "approved" &&
                <div className='mt-5'>
                    <Promote application={application} onMove={onMove} onCreate={onCreate} creditLimitIncrease={true} />
                </div>
            }
            {
                selectOption === "rejected" &&
                <div className='mt-auto px-4 py-3 text-right sm:px-6 bg-white'>
                    <button onClick={handleRejectCreditLimitIncrease}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
                    >
                        Rejected request
                    </button>
                </div>
            }
        </div >
    )
}

export default ApplyIncreaseLimit