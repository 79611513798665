import { 
    ArrowPathIcon, 
    ArrowUpCircleIcon, 
    CheckBadgeIcon, 
    ExclamationTriangleIcon, 
    HandThumbDownIcon, 
    HandThumbUpIcon, 
    PlusIcon, 
    StopIcon,
    ClockIcon,
    EnvelopeOpenIcon,
    DocumentTextIcon,
    TrashIcon
} from "@heroicons/react/24/solid";
import { format } from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLog } from "redux/reducer";

const application_status = {
    verification: 'verification',
    approved: 'approved',
    rejected: 'rejected',
    black_list: 'black_list',
    promote: 'promote',
    expired: 'expired',
    mail_marketing: 'mail_marketing',
    created: 'created',
    deleted: 'deleted',
    activate_user: 'activate_user'
}

const application_reason = {
    renewal_model: 'renewal_model',
    ren_rejected: 'ren_rejected',
    withdrawn: 'withdrawn',
    direct_mail_marketing: 'direct_mail_marketing'
}

function StatusList({ data, onMove }) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const showCreateStatusPanel = () => {
        onMove("create");
    }

    const showDetails = (item) => {
        dispatch(setCurrentLog(item));
        if (item.value === "verification") {
            onMove("verification");
        }
    }

    return (<div className="grid content-start items-start h-full overflow-hidden border-t">
        <div className="w-full flex justify-between items-center p-4 bg-gray-100 border-b">
            <p>History</p>
            {(user?.is_deleted == false) && <button onClick={showCreateStatusPanel} type="button" className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi">
                <PlusIcon className="w-4 h-4" />
            </button>}
        </div>
        <ul className="h-full overflow-auto grid">
            {data && data.map((item, index) => <li key={index}>
                <div className="border-b cursor-pointer" onClick={() => { showDetails(item) }}>
                    <div
                        className="application__log flex text-sm font-medium"
                    >
                        {item.value === application_status.verification && <span className="w-10 h-initial grid place-content-center bg-yellow-400 text-white"><CheckBadgeIcon className="w-5 h-5" /></span>}
                        {item.value === application_status.approved && <span className="w-10 h-initial grid place-content-center bg-green-400 text-white"><HandThumbUpIcon className="w-5 h-5" /></span>}
                        {item.value === application_status.rejected && <span className="w-10 h-initial grid place-content-center bg-red-400 text-white"><HandThumbDownIcon className="w-5 h-5" /></span>}
                        {item.value === application_status.black_list && <span className="w-10 h-initial grid place-content-center bg-black text-white"><ExclamationTriangleIcon className="w-5 h-5" /></span>}
                        {item.value === application_status.promote && <span className="w-10 h-initial grid place-content-center bg-blue-kiwi text-white"><ArrowUpCircleIcon className="w-5 h-5" /></span>}
                        {item.value === application_status.expired && (item.reason === application_reason.renewal_model || item.reason === application_reason.ren_rejected) && <span className="w-10 h-initial grid place-content-center bg-yellow-800 text-white"><ArrowPathIcon className="w-5 h-5" /></span>}
                        {item.value === application_status.expired && item.reason === application_reason.withdrawn && <span className="w-10 h-initial grid place-content-center bg-gray-500 text-white"><ClockIcon className="w-5 h-5" /></span>}
                        {item.value === application_status.mail_marketing && item.reason === application_reason.direct_mail_marketing && <span className="w-10 h-initial grid place-content-center bg-cyan-500 text-white"><EnvelopeOpenIcon className="w-5 h-5" /></span>}
                        {item.value === application_status.created && <span className="w-10 h-initial grid place-content-center bg-sky-500 text-white"><DocumentTextIcon className="w-5 h-5" /></span>}
                        {item.value === application_status.deleted && <span className="w-10 h-initial grid place-content-center bg-red-600 text-white"><TrashIcon className="w-5 h-5" /></span>}
                        <span className="w-10 h-initial grid place-content-center bg-gray-300 text-white"><StopIcon className="w-5 h-5" /></span>
                        <div className="min-w-0 flex flex-col pl-4 py-4">
                            <p>
                                <span className="text-xs font-semibold tracking-wide uppercase mr-1">{item.value}</span>
                            </p>
                            <p>
                                {item.reason && <span> - {item.reason}</span>} {item.sub_reason && <span>{item.sub_reason}</span>}
                            </p>
                            <p>
                                {item?.member?.full_name || ""}
                            </p>
                            <span className="text-xs font-medium text-gray-500">{item.created_at ? format(new Date(item.created_at), 'MMMM dd, yyyy - p') : '----, --, --'}</span>
                        </div>
                    </div>
                </div>
            </li>)}
        </ul>
    </div>)
}

export default StatusList;