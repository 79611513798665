import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/reducer';
import { states_offers, general_loan_offers } from 'utils/loan_offers';
import { isMilitary } from 'utils/user-validations';
import CreditLimitIncrease from 'services/CreditLimitIncrease/CreditLimitIncrease.services';
import Api from 'redux/api';
import ApprovedOfferModal from 'components/Modals/ApprovedOffer';
import { applicationTermsValidation } from 'utils/application';
import ModalNewTerms from 'components/ModalNewTerms';

const ALLOW_TOP_OFFERS = ['superagent', 'admin'];
const OFAC_MATCH = 'OFAC_VALIDATION';

const Promote = ({
  application,
  onMove,
  onCreate,
  creditLimitIncrease = false,
}) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [loanOffers, setLoanOffers] = useState('');
  const [indexOffert, setIndexOffert] = useState('');
  const user = useSelector(state => state.user);

  const currentState = states_offers.find(
    stateOffer => stateOffer.state === user.state
  );

  const [loan_offers] = useState(
    ALLOW_TOP_OFFERS.includes(auth?.profile)
      ? currentState?.loan_offers ?? general_loan_offers
      : currentState?.loan_offers.filter(offer => offer.loan_max <= 1000)
  );

  const [custom, setCustom] = useState(false);
  const [form, setForm] = useState({
    interest_rate: null,
    credit_limit: null,
    term_months: null,
    fee: null,
    archived: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [confirmOffer, setConfirmOffer] = useState(false);
  const [modalConfirmOffer, setModalConfirmOffer] = useState(false);

  const closeModalConfirmOffer = () => {
    setModalConfirmOffer(!modalConfirmOffer);
  };

  const confirmOfferModal = () => {
    setConfirmOffer(true);
    closeModalConfirmOffer();
  };

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (!custom) setLoanOffers(0);
  }, [custom]);

  useEffect(() => {
    let item = loan_offers.find(e => e.option == loanOffers);

    setForm({
      interest_rate: isMilitary(user) ? 25 : item?.interest_rate || '',
      credit_limit: item?.loan_max || '',
      term_months: item?.term_months || 0,
      fee: null,
      archived: false,
    });
  }, [loanOffers]);

  const validate_form = () => {
    if (
      !confirmOffer &&
      ALLOW_TOP_OFFERS.includes(auth?.profile) &&
      form.credit_limit > 1000
    ) {
      return setModalConfirmOffer(true);
    }

    if (!custom && !loanOffers) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Select a Loan Offers',
        })
      );
    } else {
      let interest_rate = parseFloat(form.interest_rate) || 0;
      let credit_limit = parseFloat(form.credit_limit) || 0;
      let term_months = form.term_months;

      if (parseFloat(interest_rate) < 1 || parseFloat(interest_rate) > 150) {
        dispatch(
          actions.setMessage({
            type: 'wrong',
            title: 'Wrong',
            message: 'Interest rate, out of range',
          })
        );
      } else if (
        parseFloat(credit_limit) < 0 ||
        parseFloat(credit_limit) > 2000
      ) {
        dispatch(
          actions.setMessage({
            type: 'wrong',
            title: 'Wrong',
            message: 'Loan max, out of range',
          })
        );
      } else if (!term_months) {
        dispatch(
          actions.setMessage({
            type: 'wrong',
            title: 'Wrong',
            message: 'Select a term months',
          })
        );
      } else {
        const response = applicationTermsValidation({
          application: application,
          data: form,
        });

        response ? setShowModal(!showModal) : create();
      }
    }
  };

  const create = async () => {
    try {
      await Api.updateApplication({
        id: application.id,
        data: form,
      });
      const response = await Api.createLoanStatus({
        application_id: application.id,
        value: 'promote',
        reason: creditLimitIncrease ? 'increase_limit' : null,
        member_id: auth.id,
      });

      if (creditLimitIncrease) {
        await CreditLimitIncrease.putApprovedCreditLimitIncrease({
          application_id: application.id,
          reason: null,
          credit_limit: parseFloat(form.credit_limit),
          interest_rate: parseFloat(form.interest_rate),
        });
      }

      onCreate(response.data.status);
      onMove('list');
    } catch (error) {
      if (error?.response?.data?.status === OFAC_MATCH) {
        dispatch(
          actions.setMessage({
            type: 'wrong',
            title: 'OFAC MATCH',
            message: 'Action not allowed',
          })
        );
      }
    }
  };

  return (
    <>
      <div className="mt-auto">
        <label className="block text-sm font-medium text-gray-700">
          Loan Offers
        </label>
        <select
          onChange={e => {
            setLoanOffers(e.target.value);
            setIndexOffert(e.target.selectedIndex - 1);
          }}
          value={loanOffers}
          disabled={custom}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
        >
          <option>Choose an option</option>
          {loan_offers.map((item, key) => (
            <option value={item.option} key={item.option}>
              {`$${item.loan_max} - ${item.term_months}Months - ${
                isMilitary(application?.user) ? 25 : item.interest_rate
              }%`}
            </option>
          ))}
        </select>

        <div className="flex items-center justify-between mt-1">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 text-blue-kiwi focus:ring-blue-kiwi border-gray-300 rounded"
              onChange={e => setCustom(e.target.checked)}
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900"
            >
              Custom
            </label>
          </div>
        </div>

        <div className="py-5 bg-white mb-auto">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Interest Rate
                </label>
                <input
                  type="text"
                  name="interest_rate"
                  onChange={changeHandler}
                  value={
                    isMilitary(application?.user) ? 25 : form.interest_rate
                  }
                  disabled={!custom}
                  className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Loan Max
                </label>
                <input
                  type="number"
                  name="credit_limit"
                  onChange={changeHandler}
                  value={form.credit_limit}
                  disabled={!custom}
                  className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email-address"
                className="block text-sm font-medium text-gray-700"
              >
                Term Months
              </label>
              <select
                name="term_months"
                onChange={changeHandler}
                value={form.term_months}
                disabled={!custom}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
              >
                <option>Choose an option</option>
                {[4, 6, 9, 10, 11, 12, 18, 24].map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className="hidden">
              <label
                htmlFor="email-address"
                className="block text-sm font-medium text-gray-700"
              >
                Fee
              </label>
              <input
                type="text"
                name="fee"
                onChange={changeHandler}
                value={form.fee}
                disabled={!custom}
                className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
        <div className="mt-auto px-4 py-3 bg-white text-right sm:px-6">
          <button
            onClick={validate_form}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
          >
            Create Status
          </button>
        </div>
      </div>

      <ModalNewTerms
        application={application}
        newData={form}
        showModal={showModal}
        onSubmit={() => create()}
        closeModal={() => setShowModal(!showModal)}
      />

      <ApprovedOfferModal
        isOpen={modalConfirmOffer}
        closeModal={closeModalConfirmOffer}
        confirmOffer={confirmOfferModal}
      />
    </>
  );
};

export default Promote;
