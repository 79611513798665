export const applicationTermsValidation = ({ application, data }) => {
  const { term_months, credit_limit, interest_rate } = application;

  if (!term_months && !credit_limit && !interest_rate) {
    return false;
  } else if (
    term_months !== data.term_months ||
    credit_limit !== data.credit_limit ||
    interest_rate !== data.interest_rate
  ) {
    return true;
  }
};
