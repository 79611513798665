import { useState } from 'react';
import Api from 'redux/api';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/reducer';
import { useEffect } from 'react';
import Help from 'components/UI/Help';

import {
  states_offers,
  general_loan_offers,
  general_interest_rate,
} from 'utils/loan_offers';
import { isMilitary } from 'utils/user-validations';
import { applicationTermsValidation } from 'utils/application';
import ModalNewTerms from 'components/ModalNewTerms';
import ApprovedOfferModal from 'components/Modals/ApprovedOffer';

const reasons_list = [
  {
    label: 'Credit Score',
    value: 'credit_score',
  },
  {
    label: 'Plaid',
    value: 'plaid',
  },
  {
    label: 'Credit Score + Plaid',
    value: 'credit_score+plaid',
  },
  {
    label: 'Credit Score + Bank Statements',
    value: 'credit_score+bank_statements',
  },
  {
    label: 'Bank Statements',
    value: 'bank_statements',
  },
  {
    label: 'Paystubs',
    value: 'paystubs',
  },
];

const ALLOW_TOP_OFFERS = ['superagent', 'admin'];
const OFAC_MATCH = 'OFAC_VALIDATION';

const Approved = ({ application, onMove, onCreate }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.user);

  const currentState = states_offers.find(
    stateOffer => stateOffer.state === user.state
  ) ?? { range_interest_rate: general_interest_rate };

  const [loan_offers] = useState(
    ALLOW_TOP_OFFERS.includes(auth?.profile)
      ? currentState?.loan_offers ?? general_loan_offers
      : currentState?.loan_offers.filter(offer => offer.loan_max <= 1000)
  );

  const [reason, setReason] = useState('');
  const [loanOffers, setLoanOffers] = useState('');
  const [indexOffert, setIndexOffert] = useState('');
  const [custom, setCustom] = useState(false);
  const [form, setForm] = useState({
    interest_rate: null,
    credit_limit: null,
    term_months: null,
    fee: null,
    archived: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [confirmOffer, setConfirmOffer] = useState(false);
  const [modalConfirmOffer, setModalConfirmOffer] = useState(false);

  const closeModalConfirmOffer = () => {
    setModalConfirmOffer(false);
  };

  const confirmOfferModal = () => {
    setConfirmOffer(true);
    closeModalConfirmOffer();
  };

  const handleReason = event => {
    setReason(event.target.value);
  };

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (!custom) setLoanOffers(0);
  }, [custom]);

  useEffect(() => {
    let item = loan_offers[indexOffert];
    setForm({
      interest_rate: isMilitary(user) ? 25 : item?.interest_rate || '',
      credit_limit: item?.loan_max || '',
      term_months: item?.term_months || 0,
      fee: null,
      archived: false,
    });
  }, [loanOffers]);

  const onSubmit = () => {
    if (
      !confirmOffer &&
      ALLOW_TOP_OFFERS.includes(auth?.profile) &&
      form.credit_limit > 1000
    ) {
      return setModalConfirmOffer(true);
    }
    if (!canCreate()) return;

    const response = applicationTermsValidation({
      application: application,
      data: form,
    });

    response ? setShowModal(!showModal) : approveRequest();
  };

  const canCreate = () => {
    if (!reason) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Select a reason',
        })
      );
      return false;
    }

    if (!custom && !loanOffers) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Select a Loan Offers',
        })
      );
      return false;
    }

    let interest_rate = parseFloat(form.interest_rate) || 0;
    let credit_limit = parseFloat(form.credit_limit) || 0;
    let term_months = form.term_months;

    if (
      parseFloat(interest_rate) < currentState.range_interest_rate.min ||
      parseFloat(interest_rate) > currentState.range_interest_rate.max
    ) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Interest rate, out of range',
        })
      );
      return false;
    }
    if (parseFloat(credit_limit) < 100 || parseFloat(credit_limit) > 2000) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Loan max, out of range',
        })
      );
      return false;
    }
    if (!term_months) {
      dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Select a term months',
        })
      );
      return false;
    }
    if (currentState.state === 'Florida') {
      if (parseFloat(credit_limit) < 150 || parseFloat(credit_limit) > 1000) {
        dispatch(
          actions.setMessage({
            type: 'wrong',
            title: 'Wrong',
            message: 'Loan max, out of range',
          })
        );
        return false;
      }

      if (term_months < 2 || term_months > 3) {
        dispatch(
          actions.setMessage({
            type: 'wrong',
            title: 'Wrong',
            message: 'Select between 60 days or 90 days',
          })
        );
        return false;
      }
    }
    return true;
  };

  const approveRequest = async () => {
    try {
      await Api.updateApplication({
        id: application.id,
        data: form,
      });
      const response = await Api.createLoanStatus({
        application_id: application.id,
        value: 'approved',
        reason,
        member_id: auth.id,
      });
      await Api.updateUser({ ...user, black_list: false });
      onCreate(response.data.status);
      onMove('list');
    } catch (error) {
      if (error?.response?.data?.status === OFAC_MATCH) {
        return dispatch(
          actions.setMessage({
            type: 'wrong',
            title: 'OFAC MATCH',
            message: 'Action not allowed',
          })
        );
      }
      return dispatch(
        actions.setMessage({
          type: 'wrong',
          title: 'Wrong',
          message: 'Error in the creation of the approval',
        })
      );
    }
  };

  return (
    <>
      <div className="mt-auto">
        <Help message={'Choose a reason why it is approved'}>
          <label
            htmlFor="country"
            className="block text-sm font-medium text-gray-700"
          >
            Reasons
          </label>
        </Help>
        <select
          onChange={handleReason}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
        >
          <option>Choose an option</option>
          {reasons_list.map(item => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
        </select>

        <label className="block text-sm font-medium text-gray-700 mt-5">
          Loan Offers
        </label>
        <select
          onChange={e => {
            setLoanOffers(e.target.value);
            setIndexOffert(e.target.selectedIndex - 1);
          }}
          value={loanOffers}
          disabled={custom}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
        >
          <option>Choose an option</option>
          {loan_offers.map(item => (
            <option value={item.option} key={item.option}>
              {`$${item.loan_max} - ${item.term_months}Months - ${
                isMilitary(application?.user) ? 25 : item.interest_rate
              }%`}
            </option>
          ))}
        </select>

        {currentState.canStateCustomOffer ? (
          <div className="flex items-center justify-between mt-1">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-blue-kiwi focus:ring-blue-kiwi border-gray-300 rounded"
                onChange={e => setCustom(e.target.checked)}
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Custom
              </label>
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="py-5 bg-white mb-auto">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Interest Rate
                </label>
                <input
                  type="text"
                  name="interest_rate"
                  onChange={changeHandler}
                  value={
                    isMilitary(application?.user) ? 25 : form.interest_rate
                  }
                  disabled={!custom}
                  className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Loan Max
                </label>
                <input
                  type="number"
                  name="credit_limit"
                  onChange={changeHandler}
                  value={form.credit_limit}
                  disabled={!custom}
                  className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div>
              <Help message={'Select the term of months'}>
                <label
                  htmlFor="email-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Term Months
                </label>
              </Help>
              <select
                name="term_months"
                onChange={changeHandler}
                value={form.term_months}
                disabled={!custom}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-kiwi focus:border-blue-kiwi sm:text-sm"
              >
                <option>Choose an option</option>
                {[2, 3, 4, 6, 9, 10, 11, 12, 15, 18, 24].map(item => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className="hidden">
              <label
                htmlFor="email-address"
                className="block text-sm font-medium text-gray-700"
              >
                Fee
              </label>
              <input
                type="text"
                name="fee"
                onChange={changeHandler}
                value={form.fee}
                disabled={!custom}
                className="mt-1 focus:ring-blue-kiwi focus:border-blue-kiwi block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
        <div className="mt-auto px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            onClick={onSubmit}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-kiwi hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-kiwi"
          >
            Create Status
          </button>
        </div>
      </div>

      <ModalNewTerms
        application={application}
        newData={form}
        showModal={showModal}
        onSubmit={() => approveRequest()}
        closeModal={() => setShowModal(!showModal)}
      />

      <ApprovedOfferModal
        isOpen={modalConfirmOffer}
        closeModal={closeModalConfirmOffer}
        confirmOffer={confirmOfferModal}
      />
    </>
  );
};

export default Approved;
