/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Loader from "components/UI/Loader";
import Filters from "components/Loans/Filters"
import ListWithPagination from "components/Loans/List";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducer";
import { formatToDollars } from "utils/format_date";
import { LoanServices } from "services/Loan/Loan.services";

const STATUS_LOANS = {
    NO_READY: 'no_ready',
    READY_TO_FUND: 'ready_fund',
    ME_SALVE: 'mesalve',
    TRANSFERS: 'transfers',
    CREDIT_BUILDER: 'cb_actived'
}

export default function Loans() {
    const { setStartDate, setEndDate } = actions;
    const dispatch = useDispatch();
    const { status } = useParams();
    const startDate = useSelector((state) => state.startDate)
    const endDate = useSelector((state) => state.endDate)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [loans, setLoans] = useState(null);
    const [total, setTotal] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loader, setLoader] = useState(false);
    
    const fetchLoans = async (page = 1, pageSize = 50) => {
        try {
            setLoader(true);
            if(status == STATUS_LOANS.NO_READY){
                const response = await LoanServices.getNotReadyLoans({
                    page,
                    page_size: pageSize,
                    start_date: startDate,
                    end_date: endDate
                });
                setLoans(response.data.data.loans);
                setTotal(response.data.data.total)
                setTotalAmount(response.data.data.totalAmount)
                setLoader(false);
                return;    
            }

            if(status == STATUS_LOANS.READY_TO_FUND){
                const response = await LoanServices.getReadyToFoundLoans({
                    page,
                    page_size: pageSize,
                    start_date: startDate,
                    end_date: endDate
                });
                setLoans(response.data.data.loans);
                setTotal(response.data.data.total)
                setTotalAmount(response.data.data.totalAmount)
                setLoader(false);
                return;    
            }

            if(status == STATUS_LOANS.ME_SALVE){
                const response = await LoanServices.getMeSalveLoans({
                    page,
                    page_size: pageSize,
                    start_date: startDate,
                    end_date: endDate
                });
                setLoans(response.data.data.loans);
                setTotal(response.data.data.total)
                setTotalAmount(response.data.data.totalAmount)
                setLoader(false);
                return;    
            }

            if(status == STATUS_LOANS.TRANSFERS){
                const response = await LoanServices.getTransfersLoans({
                    page,
                    page_size: pageSize,
                    start_date: startDate,
                    end_date: endDate
                });
                setLoans(response.data.data.loans);
                setTotal(response.data.data.total)
                setTotalAmount(response.data.data.totalAmount)
                setLoader(false);
                return;    
            }

            if(status == STATUS_LOANS.CREDIT_BUILDER){
                const response = await LoanServices.getCBLoans({
                    page,
                    page_size: pageSize,
                    start_date: startDate,
                    end_date: endDate
                });
                setLoans(response.data.data.loans);
                setTotal(response.data.data.total)
                setTotalAmount(response.data.data.totalAmount)
                setLoader(false);
                return;    
            }
        } catch (error) {
            setLoader(false);
        }
    }

    const resetFiltersDate = async () => {
        dispatch(setStartDate(undefined))
        dispatch(setEndDate(undefined))
    }

    useEffect(() => {
        validateDates()
        if (!startDate && !endDate) {
            fetchLoans()
        }
    }, [startDate, endDate])

    const validateDates = () => {
        if (startDate > endDate || startDate === undefined || endDate === undefined) {
            setIsButtonDisabled(true)
        } else {
            setIsButtonDisabled(false)
        }
    }

    return (
        <div className="loans w-full">
            <Loader status={loader}></Loader>
            <Filters />
            <div className="h-dashboard bg-gray-100 overflow-auto px-4 sm:px-6 lg:px-8 pt-8">
                <div className="sm:flex items-end mb-4">
                    <div className="flex-auto">

                        <h1 className="text-xl font-semibold text-gray-900">{total} Loans / {formatToDollars(totalAmount)}</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A list of all loans including the user, balance, payload and status.
                        </p>
                    </div>
                    <label className="block mr-2">
                        <span className="block text-sm font-medium text-slate-700">Start Date</span>
                        <input
                            value={startDate === undefined ? '' : startDate}
                            onChange={(event) => dispatch(setStartDate(event.target.value))}
                            type="date"
                            className="input rounded h-8 text-sm cursor-pointer"
                        />
                    </label>
                    <label className={startDate === '' ? 'opacity-75 block' : 'block'}>
                        <span className="block text-sm font-medium text-slate-700">
                            End Date
                        </span>
                        <input
                            type="date"
                            value={endDate === undefined ? '' : endDate}
                            onChange={(event) => dispatch(setEndDate(event.target.value))}
                            disabled={startDate === '' ? true : false}
                            className="input rounded h-8 text-sm cursor-pointer"
                            placeholder="Select date"
                        />
                    </label>
                    <button
                        className="px-4 py-2 font-semibold text-sm bg-sky-500 text-white rounded shadow-sm ml-2 h-8"
                        disabled={isButtonDisabled}
                        onClick={() => fetchLoans(1, 50)}
                    >
                        Search
                    </button>
                    <button
                        className="px-4 py-2 font-semibold text-sm bg-sky-500 text-white rounded shadow-sm ml-2 h-8"
                        onClick={async () => await resetFiltersDate()}
                    >
                        Reset
                    </button>
                </div>
                <ListWithPagination
                    status={status}
                    loans={loans}
                    total={total}
                    fetchApplications={fetchLoans}
                    search={() => { }}
                />
            </div>
        </div>
    )
}